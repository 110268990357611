const Product = {
    reviews: 'Basé sur {{count}} avis',
    reviews_0: 'Aucun avis trouvé!',
    inStock: 'En stock ({{quantity}} articles)',
    inStock_1: 'En stock ({{quantity}} article)',
    quantity: 'Quantité',
    unavailable: 'Indisponible',
    shareOn: 'Partager sur',
    selectYourSize: 'Sélectionner une taille',
    selectYourGender: 'Sélectionner un sexe',
    selectYourColor: 'Sélectionner une couleur',
    selectYourMaterial: 'Sélectionner une matière',
    selectYourInkColor: 'Sélectionner une couleur d\'encre',
    selectNumberOfPieces: 'Sélectionner le nombre de pièces',
    selectPrintingMethod: 'Sélectionner le type d\'impression',
    selectPrintingLocation: 'Sélectionner l\'emplacement de l\'impression',
    selectNumberOfColors: 'Sélectionner le nombre de couleurs',
    'Item Color': 'Couleur',
    Material: 'La matière',
    'Printing Method': 'Type d\'impression',
    'Printing Location': 'Emplacement de l\'impression',
    'Number of colors': 'Nombre de couleurs',
    'baterij type': 'type de batterie',
    Size: 'Taille',
    Gender: 'Sexe',
    Brand: 'Marque',
    'Ink Color': "Couleur de l'encre",
    'Battery Type': 'Type de batterie',
    numberOfPieces: 'Le nombre de pièces',
    pleaseSelect: 'Veuillez sélectionner',
    economize: 'Économisez',
    calculate: 'Calculer',
    delivery: 'Livraison',
    expedition: 'Expédition',
    allInclusivePricePerPiece: 'Prix ​​à la pièce TVA comprise',
    total: 'Total',
    addToCart: 'Ajouter au panier',
    modify: 'Modifier',
    description: 'Description',
    priceOverview: 'Aperçu des prix',
    characteristics: 'Caractéristiques',
    productsThatMayInterestYou: 'Produits susceptibles de vous intéresser',
    accessories: 'Accessoires',
    recentlyseenproducts: 'Produits récemment vus',
    pleaseSelectYourInkColor: 'Sélectionnez votre couleur d\'encre',
    pleaseSelectTheNumberOfPieces: 'Sélectionnez votre nombre de pièces',
    pleaseSelectLessThanNumberOfPieces: 'Veuillez sélectionner le nombre de pièces de moins ou égal à {{quantity}}',
    yourProductHasBeenAddedSuccessfully: 'Votre produit a été ajouté avec succès',
    yourProductHasBeenModifiedSuccessfully: 'Votre produit a été modifié avec succès',
    inkColor: 'Couleur d\'encre',
    unitPrice: 'Prix unitaire',
    reference: 'Référence',
    catalogInfo: 'Informations de catalogue',
    material: 'Matériau',
    recommendedMarkingTechnique: 'Technique de marquage recommandée',
    widthMarkingMM: 'Largeur marquage mm',
    heightMarkingMM: 'Hauteur marquage mm',
    maxNumberOfColors: 'Nombre max de couleurs',
    nativeCountry: 'Pays d\'origine',
    staticCode: 'Code statique',
    productCodeSupplier: 'Code article',
    minimumOrderQuantityDS: 'Quantité minimum',
    priceUnitBeN: 'Prix par',
    productCodeOEM: 'OEM',
    barCode: 'Bar code',
    byPiece: 'par pièce',
    free: 'Gratuit',
    tva: 'TVA',
    subtotal: 'Sous-total',
    deleteValue: 'Enlever la sélection',
    continueShopping: 'Continuer vos achats',
    goToCartPage: 'Aller à la page panier',
    WeightGR: 'Poids en gramme',
    SizeGrid: 'Grille des tailles',
    WidthCM: 'Largeur en CM',
    HeightCM: 'Hauteur en CM',
    LengthCM: 'Longueur en CM',
    notPrintable: 'Non imprimable',
    canShippedOnlyTo: 'Cet article ne peut être expédié qu\'en {{country}}',
    availableStock: 'Il ne reste que {{availableStock}} articles disponibles en stock',
    minQteError: 'La quantité minimale pour cet article est de {{minQte}}',
    invalidQuantity: 'La quantité entrée est invalide. Elle sera corrigé automatiquement.',
    printingCosts: 'Frais d\'impression',
    limitedStockError: 'une quantité inférieure à celle de stock disponible ({{quantity}})',
    priceFrom: 'À partir de',
    seeProduct: 'Voir le produit',
    error: 'Erreur',
};

export default Product;
