const Product = {
    reviews: '',
    reviews_0: 'Geen recensies gevonden!',
    inStock: 'Op voorraad ({{quantity}} items)',
    inStock_1: 'Op voorraad ({{quantity}} artikel)',
    quantity: 'Hoeveelheid',
    unavailable: 'Niet beschikbaar',
    shareOn: 'Deel op',
    selectYourSize: 'Selecteer een maat',
    selectYourGender: 'Selecteer een genre',
    selectYourColor: 'Selecteer een kleur',
    selectYourMaterial: 'Selecteer een materiaal',
    selectYourInkColor: 'Selecteer een inktkleur',
    selectNumberOfPieces: 'Selecteer uw aantal',
    selectPrintingMethod: 'Selecteer uw afdrukmethode',
    selectPrintingLocation: 'Selecteer de locatie van de print',
    selectNumberOfColors: 'Selecteer het aantal kleuren',
    'Item Color': 'Kleur',
    Material: 'Materiaal',
    'Printing Method': 'Type bedrukking',
    'Printing Location': 'Locatie van bedrukking',
    'Number of colors': 'Aantal kleuren',
    'baterij type': 'Type batterij',
    Size: 'Grootte',
    Gender: 'Type',
    Brand: 'Grootte',
    'Ink Color': 'Inktkleur',
    'Battery Type': 'Type batterij',
    numberOfPieces: 'Aantal',
    pleaseSelect: 'Selecteer aub',
    economize: 'Bespaar',
    calculate: 'Bereken',
    delivery: 'Levering',
    expedition: 'Verzending',
    allInclusivePricePerPiece: 'Prijs per stuk inclusief BTW',
    total: 'Totaal',
    addToCart: 'Toevoegen aan winkelwagentje',
    modify: 'Bewerken',
    description: 'Beschrijving',
    priceOverview: 'Prijsoverzicht',
    characteristics: 'Kenmerken',
    productsThatMayInterestYou: 'Producten die mogelijk interessant zijn voor u',
    accessories: 'Accessoires',
    recentlyseenproducts: 'Onlangs bekeken producten',
    pleaseSelectYourInkColor: 'Selecteer uw inktkleur',
    pleaseSelectTheNumberOfPieces: 'Selecteer uw aantal',
    pleaseSelectLessThanNumberOfPieces: 'Selecteer het aantal stuks kleiner dan of gelijk aan {{quantity}}',
    yourProductHasBeenAddedSuccessfully: 'Uw product is succesvol toegevoegd',
    yourProductHasBeenModifiedSuccessfully: 'Uw product is succesvol aangepast',
    inkColor: 'Kleur van inkt',
    unitPrice: 'Prijs per stuk',
    reference: 'Referentie',
    catalogInfo: 'Catalogus informatie',
    materiaal: 'Materiaal',
    recommendedMarkingTechnique: 'Aanbevolen markeertechniek',
    widthMarkingMM: 'Breedtemarkering mm',
    heightMarkingMM: 'Hoogtemarkering mm',
    maxNumberOfColors: 'Max aantal kleuren',
    nativeCountry: 'Land van herkomst',
    staticCode: 'Statische code',
    productCodeSupplier: 'Artikelcode',
    minimumOrderQuantityDS: 'Minimum hoeveelheid',
    priceUnitBeN: 'Prijs per',
    productCodeOEM: 'OEM',
    barCode: 'Barcode',
    byPiece: 'Per stuk',
    free: 'Gratis',
    tva: 'BTW',
    subtotal: 'Subtotaal',
    deleteValue: 'Selectie verwijderen',
    continueShopping: 'Doorgaan met winkelen',
    goToCartPage: 'Ga naar je winkelwagentje',
    WeightGR: 'Gewicht in gram',
    SizeGrid: 'Maattabel',
    WidthCM: 'Breedte in CM',
    HeightCM: 'Hoogte in CM',
    LengthCM: 'Lengte in CM',
    notPrintable: 'Niet bedrukbaar',
    canShippedOnlyTo: 'Dit artikel kan alleen naar {{country}} worden verzonden',
    availableStock: 'Er zijn nog maar {{availableStock}} artikelen op voorraad',
    minQteError: 'De minimum hoeveelheid voor dit artikel is {{minQte}}',
    invalidQuantity: 'Het ingevoerde aantal is ongeldig. Het wordt automatisch gecorrigeerd.',
    printingCosts: 'Drukkosten',
    limitedStockError: 'Minder dan beschikbare voorraad ({{quantity}})',
    priceFrom: 'Vanaf',
    seeProduct: 'Bekijk het product',
    Error: 'Fout',
};

export default Product;
