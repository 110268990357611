import { darken } from 'polished';

export const FONT = 'AktivGrotesk';
export const FONT_ICON = 'flyericons';
export const CONTAINER = '1280px';

export const DARK = '#29333E';
export const GREY = '#72797e';
export const GREY_MEDIUM = '#555D65';
export const GREY_LIGHT = '#9EB0BC';
export const GREY_LIGHTER = '#e2eaef';
export const GREY_LIGHTNESS = '#f0f3f6';
export const BLUE = '#4392E8';
export const BLUE_LIGHT = '#4cc3fd';
export const RED = '#DD0E3A';
export const RED_PINK = '#F13148';
export const RED_PINK_LIGHT = '#FF647D';
export const ORANGE = '#F07837';
export const GREEN = '#19DF7B';
export const GOLD = '#ffc300';

export const THEME = {
    gadgets: {
        primary: BLUE,
        secondary: BLUE_LIGHT,
        normal: GREY,
        light: GREY_LIGHT,
        dark: DARK,
        danger: RED,
        success: GREEN,
        lighter: GREY_LIGHTER,
        lightness: GREY_LIGHTNESS,
        medium: GREY_MEDIUM,
        dark_primary: darken(0.1, BLUE),
        dark_secondary: darken(0.1, BLUE_LIGHT),
    },
    office: {
        primary: RED_PINK,
        secondary: RED_PINK_LIGHT,
        normal: GREY,
        light: GREY_LIGHT,
        dark: DARK,
        danger: RED,
        success: GREEN,
        lighter: GREY_LIGHTER,
        lightness: GREY_LIGHTNESS,
        medium: GREY_MEDIUM,
        dark_primary: darken(0.1, RED_PINK),
        dark_secondary: darken(0.1, RED_PINK_LIGHT),
    },
};
