const Catalog = {
    productsFound: 'producten gevonden',
    productsFound_1: 'product gevonden',
    sortBy: 'Sorteer op',
    select: 'Kiezen',
    ascendingPrice: 'Oplopende prijs',
    descendingPrice: 'Desendant Award',
    nameAZ: 'Naam A-Z',
    nameZA: 'Naam Z-A',
    productsPerPage: 'Producten per pagina',
    next: 'volgend',
    previous: 'Vorig',
    first: 'Eerste',
    last: 'Laatste',
    colors: 'Kleuren',
    color: 'kleur',
    brand: 'Mark',
    price: 'Prijs',
    filter: 'Filters',
    Go: 'Filteren',
    category: 'Categorie',
    noProduct: 'Geen producten gevonden',
    FindProduct: 'Zoek een merk, een product, ...',
    search: 'Zoeken',
    Result_of_your_search: 'Productresultaat',
    Sorry_not_find: 'Sorry, we konden geen overeenkomend product vinden voor',
    TryAgain: 'Probeer het opnieuw door de spelling te controleren of een kortere zoekopdracht uit te voeren.',
    noProductsFound: 'Geen producten gevonden',
    ClearYourSelection: 'Wis uw selectie',
};

export default Catalog;
