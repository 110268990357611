/* eslint-disable no-useless-escape */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import Cookies from 'universal-cookie/cjs';
import assign from 'lodash/assign';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { GREY_LIGHTNESS, BLUE } from '@src/assets/styles/settings.styled';

export const IS_BROWSER = typeof window !== 'undefined';

export const LOAD_GTAG_SCRIPTS = process.env.NEXT_PUBLIC_SHOP_NAME === 'gadgets';

export const LANDING_LOCALES = {
    benl: 'NL-BE',
    befr: 'FR-BE',
};

export const CS_TIMES = ['09:00', '17:00'];
export function getCSWorkingTimes(locale) {
    const mapTime = {
        befr: { start: '9h00', end: '17h00' },
        benl: { start: '9u00', end: '17u00' },
    };

    return mapTime[locale];
}

export const scrollbarProps = {
    trackYProps: { style: { width: 5, backgroundColor: GREY_LIGHTNESS } },
    thumbYProps: { style: { backgroundColor: BLUE } },
    style: { width: '100%', height: 300, position: 'relative' },
};

/**
 * The role of this function is to return generated string of action type merged with status. This funtion is always used with AJAX requests for Redux
 * @param {string} action This parameter represents the action types of Redux
 * @param {string} status This parameter can be req, res, err
 * @example
 *
 *      actionCreator('GET_USER_DATA', 'req');
 *      => GET_USER_DATA_REQUEST
 *
 *      actionCreator('GET_USER_DATA', 'res');
 *      => GET_USER_DATA_SUCCESS
 *
 * @returns {string}
 * @author Youssef Lahssini
 */
export function actionCreator(action, status) {
    switch (status) {
    case 'req':
        return `${action}_REQUEST`;
    case 'res':
        return `${action}_SUCCESS`;
    case 'err':
        return `${action}_FAILED`;
    default:
        return `${action}_FAILED`;
    }
}

/**
 * This function allows to insert _ in the middle of language key
 * @param {string} lang This parameter represents the choosed language
 * @example
 *
 *      underLocale('befr');
 *      => be_fr
 *
 * @returns {string}
 * @author Youssef Lahssini
 */
export function underLocale(lang) {
    try {
        const position = 2;
        if (lang) {
            return [lang.slice(0, position), '_', lang.slice(position)].join('');
        }

        return 'be_en';
    } catch (e) {
        throw new Error('Error function underLocale');
    }
}

export const Cookie = new Cookies();

export const SupportedLangs = ['befr', 'benl', 'frfr', 'lufr'];

export const imageConfigurationKeys = [
    'ImageMain',
    'ImageFront',
    'ImageBack',
    'ImagePackage',
    'ImageDecoY1',
    'ImageDecoY2',
    'ImageDecoY3',
    'ImageExtra1',
    'ImageExtra2',
    'ImageExtra3',
    'ImageMood1',
    'ImagePrintLinesDefault',
    'picture_standard_200_jpg',
    'picture_200_jpg_0',
    'picture_standard_640_jpg',
    'picture_640_jpg_0',
    'picture_640_jpg_1',
    'picture_640_jpg_2',
    'picture_640_jpg_3',
    'picture_640_jpg_4',
    'picture_640_jpg_5',
];

export const popularIDS = {
    office: [
        'quantore_model_elastomapelbahawai25mmblauw',
        'quantore_model_vulpenstabilobecrazypastelrozewit',
        'quantore_model_nietmachineleitz5501246266inleg60mmrood',
        'quantore_model_etiketaveryzweck307838x29mm40st',
        'quantore_model_lamineermachinefellowesa3l125',
        'quantore_model_interieurquantorea423r70gramruit10mm',
        'quantore_model_badgedurable8002metclip90x60mm',
        'quantore_model_inktkussencolopmicro19x5cmrood',
        'quantore_model_inkcartridgeepson26t2614geel',
        'quantore_model_envquantorecirculeb4170grzkcreme',
    ],
    gadgets: [
        'pf_360_brand_it_digital__decorated_sky_tritan_sport_bottle',
        'pf_johannesburg_ballpoint_pen',
        'pf_madras_140_gm_cotton_tote_bag',
        'pf_viera_apron_with_2_pockets',
        'pf_calgary_short_sleeve_mens_polo',
    ],
};

export const recommendedIDS = {
    office: [
        'quantore_model_armleggereuroseatsbrussel005vastehoogte',
        'quantore_model_papierbakvlamdovervepabins15literr255cmgrijs',
        'quantore_model_kluispavomini230x170x170mmelektronischblauw',
        'quantore_model_laserpointerlegamasterlx2metallicbatterijen',
        'quantore_model_inkjetprintercanonpixmats305zwart',
        'quantore_model_labelmanagerdymolm160pazerty',
        'quantore_model_papiervernietigerfellowes8cd4x35mm',
        'quantore_model_briefwegermaulgoal164605000grgroen',
        'quantore_model_rolsnijmachinedesq312hobby32cmlang',
        'quantore_model_laptoptasistay133is0107zwart',
    ],
    gadgets: [
        'pf_oregon_matte_400_ml_sport_bottle_with_carabiner',
        'pf_oriole_premium_drawstring_backpack',
        'pf_spectrum_a6_hard_cover_notebook',
        'pf_rotatebasic_8gb_usb_flash_drive',
        'pf_zuse_a7_recycled_jotter_notepad_with_pen',
    ],
};

export const bannedSegments = ['63fac81e-9be8-4602-9879-e34a26164a6', '63fac81e-9be8-4602-9879-e34a26164a61'];

export const bannedSubCatalogs = ['d4386cc6-6adc-45c6-8b0f-a91f2849f892', '3d0171b5-0968-428e-8869-bc6e437141b6', '3c7b6b52-8334-40da-bb78-8c9ac45a740c'];

export const stepKeys = ['Item Color', 'Material', 'Printing Method', 'Printing Location', 'Number of colors'];

export const manufacturingSteps = ['Printing Method', 'Printing Location', 'Number of colors'];

export const uselessStepKeys = ['Model', 'Brand', 'Logo size'];

export const translateStepKeys = {
    'Item Color': 'selectYourColor',
    'Ink Color': 'selectYourInkColor',
    Material: 'selectYourMaterial',
    Size: 'selectYourSize',
    Gender: 'selectYourGender',
    'Printing Method': 'selectPrintingMethod',
    'Printing Location': 'selectPrintingLocation',
    'Number of colors': 'selectNumberOfColors',
};

export const partnerKeys = {
    gadgets: 'pf',
    office: 'office',
};

export const isDev = process.env.NEXT_PUBLIC_ENV === 'preprd' ? '🔥 ' : '';

export async function sleep(ms) {
    await new Promise((resolve) => { setTimeout(resolve, ms); });
}

/**
 * Replace spaces with -, Remove all non-word chars, Replace multiple - with single - Trim - from start & end of text
 * @param {string} text Text to slugify
 * @example
 *
 *      slugify('Balles anti-stress')
 *      => balles-anti-stress
 *
 * @author Youssef Lahssini
 */
export function slugify(text) {
    return text.toString().toLowerCase().replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

/**
 * The rest api use 'be_fr' for french. For example the website with extension '.fr' use 'fr_fr' for key translation.
 * And it not exist in rest api, we force to use be_fr same case with lu_fr
 * @param {string} locale The value could be fr_fr, lu_fr, be_fr and be_nl
 */
export function adaptLocaleForLuFr(locale) {
    if (locale === 'fr_fr' || locale === 'be_fr') {
        return 'be_fr';
    }

    return locale;
}

/**
 * The role of this function is to get the name of category (segment | product group) based on reseller name and the choosed language
 * @param {object} category This parameter is iteration of list of menu from the rest
 * @param {string} locale This parameter represent the language used
 * @param {boolean} clean When is true, we clean the string from special characters like 'é,è...'
 * @returns {string}
 * @author Youssef Lahssini
 */
export function getCategoryTitle(category, locale, clean = false) {
    try {
        let title = null;

        if ('title' in category) {
            title = category.title.find((item) => item.language === adaptLocaleForLuFr(underLocale(locale)));
        } else {
            title = category.name.find((item) => item.language === adaptLocaleForLuFr(underLocale(locale)));
        }

        if (clean) {
            return (title?.value || '').toLowerCase().replace(/(é|è|ê)/g, 'e');
        }

        return title?.value || null;
    } catch (e) {
        return null;
    }
}

/**
 * The role of this function is to get the name of segment based on reseller name and the choosed language
 * @param {object} segment This parameter is iteration of list of menu from the rest
 * @param {string} locale This parameter represent the language used
 * @param {boolean} clean When is true, we clean the string from special characters like 'é,è...'
 * @returns {string}
 * @author Youssef Lahssini
 */
export function getSegmentTitle(segment, locale, clean = false) {
    try {
        const segmentName = segment.title.find((item) => item.language === adaptLocaleForLuFr(locale));

        if (clean) {
            return segmentName.value.toLowerCase().replace(/(é|è|ê)/g, 'e');
        }

        return segmentName?.value || null;
    } catch (e) {
        return null;
    }
}

/**
 * The role of this function is to get the description of segment based on reseller description and the choosed language
 * @param {object} segment This parameter is iteration of list of segment from the rest
 * @param {string} locale This parameter represent the language used
 * @returns {string}
 * @author Zakariya Louardi
 */
export function getSegmentDescription(segment, locale) {
    try {
        const translate = segment.description.find((item) => item.language === adaptLocaleForLuFr(underLocale(locale)));
        return translate.value;
    } catch (e) {
        return null;
    }
}

export function levelParams(categories) {
    const mapLevel = ['One', 'Two', 'Three', 'Four'];
    return categories.reduce((acc, curr, i) => ({ ...acc, [`level${mapLevel[i]}`]: curr }), {});
}

/**
 * The role of this function is to get the products generate URL of segment based on reseller title and the choosed language
 * @returns {string}
 * @author Zakariya Louardi
 */
export function generateUrl({ locale, levelOne, levelTwo, levelThree }) {
    let link = `/${locale}/`;

    if (levelOne) {
        const slugLevelOne = `${levelOne.slug[adaptLocaleForLuFr(underLocale(locale))]}`;
        link += `${slugLevelOne}/`;
    }

    if (levelTwo) {
        const slugLevelTwo = `${levelTwo.slug[adaptLocaleForLuFr(underLocale(locale))]}`;
        link += `${slugLevelTwo}/`;
    }

    if (levelThree) {
        const slugLevelThree = `${levelThree.slug[adaptLocaleForLuFr(underLocale(locale))]}`;
        link += `${slugLevelThree}/`;
    }

    return link;
}

/**
 * The role of this function is generate domain for url based on extension of domain name with possibility to add lang
 * @param {object} layout This param is actually the layout reducer that contains url and lang properties
 * @param {boolean} nolang Exclude lang in url
 * @returns {string}
 * @example
 *
 *      generateDomain({ website: 'https://www.flyer.be', lang: 'benl' });
 *      => https://www.flyer.be/benl
 *
 *      generateDomain({ website: 'https://preprd.flyer.lu', lang: 'lufr' }, true);
 *      => https://preprd.flyer.lu
 *
 * @author Youssef Lahssini
 */
export function generateDomain({ website, api, lang }, nolang, isApi) {
    // console.log('website', { website, api, lang });
    // console.log('nolang', nolang);
    // console.log('isApi', isApi);
    let url = '';

    if (isApi) {
        const language = api.includes('be') ? `/${lang}` : '';
        url = `${api}${!nolang ? language : ''}`;
    } else {
        const language = website.includes('be') ? `/${lang}` : '';
        url = `${website}${!nolang ? language : ''}`;
    }

    return url;
}

/**
 * Get the value of the property (Title, color, brand ...)
 * We can set language key to get translated value, if the value is undefined it will return first one. In our case is 'be_en'
 * @param {object} property This param is iteration of property listing
 * @param {string} key This param could be (name, description)
 * @param {string} locale This param represent the key of language. In our case it could be (be_fr, be_nl)
 * @author Ayoub Lahraoui - Youssef Lahssini
 */
export function getConfigurationValue(property, key, locale) {
    if (property) {
        if (key in property.property_value) {
            const propertyTranslated = find(property.property_value[key].translate, (t) => t.language === adaptLocaleForLuFr(locale));

            if (propertyTranslated && locale) {
                return propertyTranslated.value;
            }
        }

        let result = get(property.property_value[key], 'translate.0.value', null);

        if (result !== null) {
            return result;
        }

        result = property.property_value[key].original_name;

        return result;
    }

    return null;
}

/**
 * Get the value of the property (Title, color, brand ...)
 * We can set language key to get translated value, if there is undefined it the return first one
 * @param {object} property This param is iteration of property listing
 * @param {string} key This param is could be (name, description)
 * @param {string} locale This param represent the key of language. In our case it could be (be_fr, be_nl)
 * @author Zakariya Louardi
 */
export function getPropertyValue(property, key, locale) {
    if (property) {
        const propertyTranslated = find(property[key].translate, (t) => t.language === adaptLocaleForLuFr(locale));

        if (propertyTranslated && locale) {
            return propertyTranslated.value;
        }
    }

    return get(property[key], 'translate.0.value', null);
}

/**
 * The role of this function is to get property of configuration of REST data by mapping in configuration_property.
 * @param {object} config The iteration or selected configuration
 * @param {string} stepProperty This is the key of property like Item Color, Material, Size ...
 * @param {boolean} getIndex When this param is different of undefined, it return index of the property
 * @author Youssef Lahssini
 * @returns {object|number|null}
 */
export function getProperty(config, stepProperty, getIndex) {
    if (config) {
        if (getIndex) {
            return findIndex(config.configuration_property, (c) => c.property.name.original_name === stepProperty);
        }

        return find(config.configuration_property, (c) => c.property.name.original_name === stepProperty);
    }

    return null;
}

/**
 * This is combinaison of two function getConfigurationValue() and getProperty()
 * @param {object} configuration Represent the configuration with properties like id, configuration_property, slug
 * @param {string} property This is the key of property like Item Color, Material, Size ...
 * @param {string} key Represent which property will used name, description
 * @param {string} locale This param represent the key of language. In our case it could be (be_fr, be_nl)
 * @returns {string}
 * @author Youssef Lahssini
 */
export function getPropertyValueFromConfiguration(configuration, property, key, locale) {
    return getConfigurationValue(getProperty(configuration, property), key, adaptLocaleForLuFr(underLocale(locale)));
}

/**
 * Return object with the HEX Color as a value, it come from extra_info property in configuration
 * There is possibility that the property has two colors or more and it returns object with two keys.
 * @param {object} hit The configuration of model (iteration)
 * @returns {object}
 * @example
 *
 *      getHexColor({ extra_info: { HEXcolor: 'FF0000' } });
 *      => { color: '#FF0000' }
 *
 *      getHexColor({ extra_info: { HEXcolor: 'FF0000,33FF66' } });
 *      => { color: '#FF0000', color2: '#33FF66' }
 *
 * @author Youssef Lahssini
 */
export function getHexColor(hit) {
    if (hit === undefined) {
        return null;
    }

    const HEXcolor = get(hit, 'extra_info.HEXcolor', '').split(',');
    const colors = {};

    HEXcolor.forEach((color, i) => {
        if (i === 0) {
            colors.color = `#${color}`;
        } else {
            colors[`color${i + 1}`] = `#${color}`;
        }
    });

    return colors;
}

/**
 * Return a percentage based on the first price of the lowest qunatity with the price of the selected quantity.
 * @param {object} currentCost The price of quantiy
 * @param {object} firstCost The price of unity
 * @author Youssef Lahssini
 * @example
 *
 *      calculatePercentage({ quantity: 50, sell_price: 81.90 }, { quantity: 1, sell_price: 33.49 })
 *      => 95
 *
 * @returns {number} Percentage converted to an integer
 */
export function calculatePercentage(currentCost, getManufacturingQuantityPrice, firstCost) {
    const priceQuantity = currentCost.sell_price
        ? ((firstCost.sell_price * currentCost.quantity) / firstCost.quantity) + getManufacturingQuantityPrice
        : (firstCost.sell_price * currentCost.quantity) / firstCost.quantity;
    const differenceWith = priceQuantity - currentCost.sell_price;
    const result = (differenceWith / priceQuantity) * 100;
    return result.toFixed(0);
}

/**
 * This function generate the url of image based on CDN url
 * @param {object} zoom This parameter is representing the iteration of zoom property in productPartner reducer
 * @param {string} size This parameter is used when the zoom name property is 'ImageMain'. The value of this parameter must be ('250x250', '500x500', 'full') other that image will be not found
 * @example
 *
 *       generateImageUrl({ name: 'ImageMain', url: '10025000.jpg', reseller: 'gadgets' }, '');
 *      => https://d327kdaxjd7x69.cloudfront.net/front/images/products/full/10025000.jpg
 *
 *      generateImageUrl({ name: 'ImageMain', url: '10025000.jpg', reseller: 'gadgets' }, '250x250');
 *      => https://d327kdaxjd7x69.cloudfront.net/front/images/products/250x250/10025000.jpg
 *
 *      generateImageUrl({ name: 'ImageFront', url: '10025000_F.jpg', reseller: 'gadgets' });
 *      => https://d327kdaxjd7x69.cloudfront.net/front/images/products/10025000_F.jpg
 *
 *      generateImageUrl({ name: 'picture_1', url: '10025000_F.jpg', reseller: 'office', code: '114500' });
 *      => https://d327kdaxjd7x69.cloudfront.net/front/images/office/500/114500/640/10025000_F.jpg
 *
 * @author Youssef Lahssini
 * @returns {string}
 */
export function generateImageUrl(image, size) {
    const url = {
        gadgets: () => `${process.env.NEXT_PUBLIC_CDN_IMAGES}products/${size || 'full'}/${image.url}`,
        office: () => `${process.env.NEXT_PUBLIC_CDN_IMAGES}${image.url}`,
    };

    if (image) {
        return url[image.reseller]();
    }

    return '';
}

/**
 * Replace '.' by ',' in price and fixed to two number after '.'
 * @param {float} price The price that we want to treated
 */
export function stringifyPrice(price) {
    if (price) {
        return price.toFixed(2).toString().replace('.', ',');
    }

    return 0;
}

/**
 * This function calcul the price based on TVA data and it return total and tva.
 * @param {number} price This parameter is the price of selected quantity in other hand the subtotal
 * @param {number} manufacturingPrice Default value of this param is 0. Because the information could be undefined and it will add to price param for result
 * @param {number} TVApercentage Default value of this param is 21%. The information should come from REST
 * @returns {object}
 * @author Youssef Lahssini
 * @example
 *
 *      calculateFinalPrice(243);
 *      => { tva: 51.03, total: 294.03 }
 *
 *      calculateFinalPrice(null);
 *      => { tva: 0, total: 0 }
 *
 *      calculateFinalPrice(243, 6.07);
 *      => { tva: 51.03, total: 300.1 }
 *
 */
export function calculateFinalPrice(price, manufacturingPrice = 0, TVApercentage = 21) {
    const resultError = {
        tva: 0,
        total: 0,
    };

    if (price) {
        try {
            const subprice = price + manufacturingPrice;
            const tva = (subprice * TVApercentage) / 100;

            return {
                tva: stringifyPrice(tva),
                total: stringifyPrice(subprice + tva),
            };
        } catch (e) {
            return resultError;
        }
    }

    console.warn('\u{1F631} The price is undefined');
    return resultError;
}

/**
 * Generate a specefic object that used to register in product partner reducer, exactly in stepSelectedModelValue or stepSelectedManufacturingValue
 * @param {object} configuration Represent the single configuration
 * @param {string} stepProperty Represent the configuration property, example: Item Color, Material, Size ...
 * @author Youssef Lahssini
 */
export function generateSetStepValues(configuration, stepProperty) {
    const propertyValue = getProperty(configuration, stepProperty);

    // Because sometimes data doesn't exist
    let translate = get(propertyValue, 'property_value.name.translate');

    if (translate && translate.length === 0) {
        translate = [{ language: 'be_en', value: propertyValue.property_value.name.original_name }];
    }

    // Because sometimes data doesn't exist
    return {
        configurationID: configuration.id,
        original_name: get(propertyValue, 'property_value.name.original_name'),
        property_value_id: get(propertyValue, 'property_value_id'),
        translate,
        translateKeys: keyBy(translate, 'language'),
        HEXcolor: stepProperty === 'Item Color' ? configuration.extra_info.HEXcolor : null,
    };
}

/**
 * This function return object with specific properties. It calculate the price based on the configuration and difference with custom quantity
 * @private
 * @param {*} configuration The configuration of cost price quantity
 * @param {*} difference The difference between the custom quantity and the selected quantity
 */
export function getDataQuantityConfiguration(configuration, difference) {
    /**
     * @todo check configuration.between_block_price in this calculation when betweenBlock is not here
     */
    const sub = configuration.between_block === 1 ? configuration.between_price : (configuration.between_price / configuration.between_block);
    const price = configuration.sell_price + (sub * difference);
    const delivery = configuration.production_day;
    const selectedID = configuration.id;

    return {
        sub,
        price,
        delivery,
        selectedID,
    };
}

/**
 * The goal of this function is to get the price from manufacturing.
 * The function will return 0 if the configuration is null or undefined or that not have cost_durations & sell_price properties.
 * @param {object} configuration This param represent selectedManufacturing property in reducer, it must have the cost_durations & sell_price properties.
 * @param {object} payload This param must be an object with one key, and this key must be existed in properties of cost_durations or sell_price like quantity, id etc...
 * @author Youssef Lahssini
 */
export function getPriceOfManufacturing(configuration, payload, customQuantity = 0) {
    let manufacturing;

    const key = Object.keys(payload)[0];
    const value = payload[key];

    if (!isNil(configuration)) {
        // const prices = sortBy(merge(configuration.cost_durations.filter(cd => !diffIDS.includes(cd.id)), configuration.sell_price.filter(cd => !diffIDS.includes(cd.id)).sort((a, b) => order[a.id] - order[b.id])), ['quantity']);
        const prices = sortBy(map(configuration.cost_durations, (obj) => assign(obj, find(configuration.sell_price, { id: obj.id }))), ['quantity']);

        manufacturing = find(prices, (c) => c[key] === value);

        if (manufacturing) {
            if (key === 'quantity' && customQuantity !== 0) {
                const difference = customQuantity - value;
                return getDataQuantityConfiguration(manufacturing, difference).price;
            }

            return manufacturing.sell_price;
        }
    }

    return 0;
}
