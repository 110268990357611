import { GET_LANDING_PAGES, LOAD_LANDING_PAGE, SET_NATIVE_LANDING } from '../types/landingPageTypes';
import { actionCreator } from '../../utils';

export const initState = {
    data: [],
    content: null,
    loading: false,
    error: null,
};

export default function landing(state = initState, action) {
    switch (action.type) {
    case actionCreator(GET_LANDING_PAGES, 'req'):
        return {
            ...state,
            loading: true,
            error: null,
        };

    case actionCreator(GET_LANDING_PAGES, 'res'):
        return {
            ...state,
            loading: false,
            data: action.data,
        };

    case actionCreator(GET_LANDING_PAGES, 'err'):
        return {
            ...state,
            loading: false,
            error: action.error,
            data: [],
        };

    case actionCreator(LOAD_LANDING_PAGE, 'req'):
        return {
            ...state,
            loading: true,
            error: null,
        };

    case actionCreator(LOAD_LANDING_PAGE, 'res'):
        return {
            ...state,
            loading: false,
            content: action.data,
        };

    case actionCreator(LOAD_LANDING_PAGE, 'err'):
        return {
            ...state,
            loading: false,
            error: action.error,
            content: [],
        };

    case SET_NATIVE_LANDING:
        return { ...state, data: action.data };

    default:
        return state;
    }
}
