export const SELECT_MODEL_BY_PROPERTY = 'SELECT_MODEL_BY_PROPERTY';
export const SELECT_MODEL_STEP_VALUE = 'SELECT_MODEL_STEP_VALUE';
export const GENERATE_MODEL_STEP_ORDER = 'GENERATE_MODEL_STEP_ORDER';
export const LOAD_PARTNER_PRODUCT = 'LOAD_PRODUCT_PARTNER';
export const LOAD_MANUFACTURING_PRODUCT = 'LOAD_MANUFACTURING_PRODUCT';
export const SELECT_MANUFACTURING_BY_PROPERTY = 'SELECT_MANUFACTURING_BY_PROPERTY';
export const SELECT_MANUFACTURING_STEP_VALUE = 'SELECT_MANUFACTURING_STEP_VALUE';
export const GENERATE_MANUFACTURING_STEP_ORDER = 'GENERATE_MANUFACTURING_STEP_ORDER';
export const SET_PRODUCT_DEFAULT_DATA = 'SET_PRODUCT_DEFAULT_DATA';
export const SET_MANUFACTURING_DEFAULT_DATA = 'SET_MANUFACTURING_DEFAULT_DATA';
export const SET_SELECTED_QUANTITY_PRICE = 'SET_SELECTED_QUANTITY_PRICE';
export const SET_CUSTOM_QUANTITY = 'SET_CUSTOM_QUANTITY';
export const PUSH_IMAGE_TO_ZOOM = 'PUSH_IMAGE_TO_ZOOM';
export const SET_IMAGE_FOR_ZOOM = 'SET_IMAGE_FOR_ZOOM';
export const SET_COUNT_FOR_ZOOM = 'SET_COUNT_FOR_ZOOM';
export const LOAD_ZOOM = 'LOAD_ZOOM';
export const SET_SELECTED_FOR_ZOOM = 'SET_SELECTED_FOR_ZOOM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_SHIPPING_DATE = 'GET_SHIPPING_DATE';
export const SET_SELECT_MODE = 'SET_SELECT_MODE';
export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const SET_JOB_ID = 'SET_JOB_ID';
export const GET_CONFIGURATION_BREADCRUMBS = 'GET_CONFIGURATION_BREADCRUMBS';
export const GET_ACCESSORIES = 'GET_ACCESSORIES';
export const GET_RECENTLY_SEEN = 'GET_RECENTLY_SEEN';
