import { createGlobalStyle } from 'styled-components';
import compact from 'lodash/compact';
import { FONT } from './settings.styled';

const fonts = [
    { weight: 100, name: 'hairlineitalic', italic: true, active: false },
    { weight: 100, name: 'hairline', italic: false, active: false },
    { weight: 300, name: 'lightitalic', italic: true, active: false },
    { weight: 300, name: 'light', italic: false, active: false },
    { weight: 400, name: 'regularitalic', italic: true, active: true },
    { weight: 400, name: 'regular', italic: false, active: true },
    { weight: 500, name: 'mediumitalic', italic: true, active: false },
    { weight: 500, name: 'medium', italic: false, active: true },
    { weight: 700, name: 'bolditalic', italic: true, active: false },
    { weight: 700, name: 'bold', italic: false, active: true },
    { weight: 800, name: 'xbolditalic', italic: true, active: false },
    { weight: 800, name: 'xbold', italic: false, active: true },
    { weight: 900, name: 'blackitalic', italic: true, active: false },
    { weight: 900, name: 'black', italic: false, active: false },
];

const FontFaceStyle = createGlobalStyle`
    ${compact(fonts.map((font) => {
        if (font.active) {
            return `
                @font-face {
                    font-family: ${FONT};
                    src: local('${FONT}'), url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/aktivgrotesk/aktivgrotesk-${font.name}-webfont.woff2') format('woff2'),
                    url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/aktivgrotesk/aktivgrotesk-${font.name}-webfont.woff') format('woff');
                    font-weight: ${font.weight};
                    font-style: ${font.italic ? 'italic' : 'normal'};
                    font-display: optional;
                }
            `;
        }

        return null;
    })).join('')}

    @font-face {
        font-family: 'Shadows Into Light';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.eot'); /* IE9 Compat Modes */
        src: local('Shadows Into Light'),
        url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.woff') format('woff'), /* Modern Browsers */
        url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('${process.env.NEXT_PUBLIC_CDN_HOST}new-front/fonts/shadows-into/shadows-into-light-regular.svg#ShadowsIntoLight') format('svg'); /* Legacy iOS */
    }
`;

export default FontFaceStyle;
