const Home = {
    customerService: 'Service Client',
    nowOpen: 'Ouvert',
    nowClosed: 'Fermé',
    csPhoneNL: '050 15 30 06',
    csPhoneFR: '026 16 57 42',
    serviceTimes: 'Joignable de {{start}} à {{end}}',
    recommendedProductsOffice: 'Produits recommandés',
    recommendedProductsGadgets: 'Produits recommandés',
    discoverOurCategories: 'Découvrez nos catégories',
    customizedOffer: 'Offre sur mesure',
    orCustomQuote: 'ou devis personnalisé ?',
    feedBackTimeHome: 'Un retour en 24h (Sauf le weekend)',
    discover: 'Découvrir',
    ourFavorites: 'Nos coups de coeur',
    buy: 'Acheter',
    popularBlock1: 'Parapluies',
    popularBlock2: 'High Tech',
    popularBlock3: 'Sacs',
    opOffreSurMesure: {
        text: 'Offre sur mesure',
        link: 'demande-personnalisee-gadget',
    },
    OfficeOffreSurMesure: {
        text: 'Offre sur mesure',
        link: 'demande-personnalisee-office',
    },
    ecologicProducts: {
        text: 'Gamme des produits écologiques chez Flyer.be',
        link: 'produits-ecologiques',
    },
    corporateGifts: {
        text: 'Cadeaux d’entreprise pour la fin d’année et le nouvel an',
        link: 'cadeaux-entreprise-fin-annee-et-nouvel-an',
    },
    subscribeNewsletter: {
        text: 'Abonnez-vous à notre newsletter de Flyer Objets Publicitaires',
        link: 'abonnez-vous-newsletter-gadgets',
    },
    subscribeNewsletterOffice: {
        text: 'Abonnez-vous à notre newsletter de Flyer Office',
        link: 'abonnez-vous-newsletter',
    },
    lpSalons: {
        text: 'Objets publicitaires et goodies pour vos salons',
        link: 'articles-promotionnels-salons',
    },
};

export default Home;
