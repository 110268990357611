import orderBy from 'lodash/orderBy';
import { actionCreator } from '@src/utils';
import * as ProductTypes from '../types/productPartnerTypes';

const initState = {
    loading: false,
    error: null,
    dataModel: null,
    selectedModel: null,
    stepModelOrder: [],
    stepSelectedModelValue: {},
    dataManufacturing: null,
    selectedManufacturing: null,
    stepManufacturingOrder: [],
    stepSelectedManufacturingValue: {},
    customQuantity: null,
    selectedQuantityPrice: null,
    shipping: {
        loading: false,
        error: false,
        date: '',
    },
    zoom: {
        loading: false,
        list: [],
        selected: null,
        count: null,
    },
    related: {
        loading: false,
        data: null,
        error: false,
    },
    accessories: {
        loading: false,
        data: null,
        error: false,
    },
    recentlyseen: {
        loading: false,
        data: null,
        error: false,
    },
    cart: {
        loading: false,
        success: null,
        error: false,
    },
    selectMode: false,
    jobId: null,
    breadcrumbs: {
        loading: false,
        data: null,
        error: null,
    },
};

export default function productPartner(state = initState, action) {
    switch (action.type) {
    case ProductTypes.PUSH_IMAGE_TO_ZOOM:
        return {
            ...state,
            zoom: {
                ...state.zoom,
                list: [
                    ...state.zoom.list,
                    action.data,
                ],
            },
        };

    case ProductTypes.SET_COUNT_FOR_ZOOM:
        return {
            ...state,
            zoom: {
                ...state.zoom,
                list: [],
                count: action.data,
            },
        };

    case ProductTypes.SET_SELECTED_FOR_ZOOM:
        return {
            ...state,
            zoom: { ...state.zoom, selected: action.data },
        };

    case actionCreator(ProductTypes.LOAD_ZOOM, 'req'):
        return {
            ...state,
            zoom: { ...state.zoom, loading: true },
        };

    case actionCreator(ProductTypes.LOAD_ZOOM, 'res'):
        return {
            ...state,
            zoom: {
                ...state.zoom,
                list: orderBy(state.zoom.list, ['order', ['asc']]),
                loading: false,
            },
        };

    case ProductTypes.SELECT_MODEL_BY_PROPERTY:
        return { ...state, selectedModel: action.product };

    case actionCreator(ProductTypes.LOAD_PARTNER_PRODUCT, 'req'):
        return {
            ...state,
            loading: true,
            error: false,
        };

    case actionCreator(ProductTypes.LOAD_PARTNER_PRODUCT, 'res'):
        return {
            ...state,
            loading: false,
            dataModel: action.data,
        };

    case actionCreator(ProductTypes.LOAD_PARTNER_PRODUCT, 'err'):
        return {
            ...state,
            loading: false,
            error: action.data ? action.data : true,
        };

    case ProductTypes.GENERATE_MODEL_STEP_ORDER:
        return { ...state, stepModelOrder: action.data };

    case ProductTypes.SELECT_MODEL_STEP_VALUE:
        return { ...state, stepSelectedModelValue: { ...state.stepSelectedModelValue, ...action.data } };

    case ProductTypes.SELECT_MANUFACTURING_BY_PROPERTY:
        return { ...state, selectedManufacturing: action.product };

    case actionCreator(ProductTypes.LOAD_MANUFACTURING_PRODUCT, 'req'):
        return { ...state, loading: 'manufacturing' };

    case actionCreator(ProductTypes.LOAD_MANUFACTURING_PRODUCT, 'res'):
        return {
            ...state,
            dataManufacturing: action.data,
            loading: false,
        };

    case ProductTypes.GENERATE_MANUFACTURING_STEP_ORDER:
        return { ...state, stepManufacturingOrder: action.data };

    case ProductTypes.SELECT_MANUFACTURING_STEP_VALUE:
        return { ...state, stepSelectedManufacturingValue: { ...state.stepSelectedManufacturingValue, ...action.data } };

    case ProductTypes.SET_PRODUCT_DEFAULT_DATA:
        return { ...state, ...initState };

    case ProductTypes.SET_MANUFACTURING_DEFAULT_DATA:
        return {
            ...state,
            dataManufacturing: null,
            selectedManufacturing: null,
            stepSelectedManufacturingValue: {},
        };

    case ProductTypes.SET_CUSTOM_QUANTITY:
        return { ...state, customQuantity: action.data };

    case ProductTypes.SET_SELECTED_QUANTITY_PRICE:
        return { ...state, selectedQuantityPrice: action.data };

    case actionCreator(ProductTypes.ADD_TO_CART, 'req'):
        return {
            ...state,
            cart: {
                ...state.cart,
                loading: true,
                error: false,
            },
        };

    case actionCreator(ProductTypes.ADD_TO_CART, 'res'):
        return {
            ...state,
            cart: {
                ...state.cart,
                loading: false,
            },
        };

    case actionCreator(ProductTypes.ADD_TO_CART, 'err'):
        return {
            ...state,
            cart: {
                ...state.cart,
                loading: false,
                error: true,
            },
        };

    case actionCreator(ProductTypes.GET_SHIPPING_DATE, 'req'):
        return {
            ...state,
            shipping: {
                ...state.shipping,
                loading: true,
            },
        };

    case actionCreator(ProductTypes.GET_SHIPPING_DATE, 'res'):
        return {
            ...state,
            shipping: {
                ...state.shipping,
                loading: false,
                date: action.data,
            },
        };

    case actionCreator(ProductTypes.GET_SHIPPING_DATE, 'err'):
        return {
            ...state,
            shipping: {
                ...state.shipping,
                loading: false,
                date: null,
                error: true,
            },
        };
    case actionCreator(ProductTypes.GET_RELATED_PRODUCTS, 'req'):
        return {
            ...state,
            related: {
                ...state.related,
                loading: true,
                error: null,
            },
        };
    case actionCreator(ProductTypes.GET_RELATED_PRODUCTS, 'res'):
        return {
            ...state,
            related: {
                ...state.related,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(ProductTypes.GET_RELATED_PRODUCTS, 'err'):
        return {
            ...state,
            related: {
                ...state.related,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case actionCreator(ProductTypes.GET_ACCESSORIES, 'req'):
        return {
            ...state,
            accessories: {
                ...state.related,
                loading: true,
                error: null,
            },
        };

    case actionCreator(ProductTypes.GET_ACCESSORIES, 'res'):
        return {
            ...state,
            accessories: {
                ...state.related,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(ProductTypes.GET_ACCESSORIES, 'err'):
        return {
            ...state,
            accessories: {
                ...state.related,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case actionCreator(ProductTypes.GET_RECENTLY_SEEN, 'req'):
        return {
            ...state,
            recentlyseen: {
                ...state.related,
                loading: true,
                error: null,
            },
        };

    case actionCreator(ProductTypes.GET_RECENTLY_SEEN, 'res'):
        return {
            ...state,
            recentlyseen: {
                ...state.related,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(ProductTypes.GET_RECENTLY_SEEN, 'err'):
        return {
            ...state,
            recentlyseen: {
                ...state.related,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case ProductTypes.SET_SELECT_MODE:
        return { ...state, selectMode: true };

    case ProductTypes.SET_JOB_ID:
        return { ...state, jobId: action.jobId };

    case actionCreator(ProductTypes.GET_CONFIGURATION_BREADCRUMBS, 'req'):
        return {
            ...state,
            breadcrumbs: {
                ...state.breadcrumbs,
                loading: true,
            },
        };

    case actionCreator(ProductTypes.GET_CONFIGURATION_BREADCRUMBS, 'res'):
        return {
            ...state,
            breadcrumbs: {
                ...state.breadcrumbs,
                data: action.breadcrumbs,
                loading: false,
            },
        };

    case actionCreator(ProductTypes.GET_CONFIGURATION_BREADCRUMBS, 'err'):
        return {
            ...state,
            breadcrumbs: {
                ...state.breadcrumbs,
                loading: false,
                error: true,
            },
        };

    default:
        return state;
    }
}
