export const GET_PRODUCTLISTING_REST = 'GET_PRODUCTLISTING_REST';
export const SET_ORIGINAL_DATA = 'SET_ORIGINAL_DATA';
export const SET_CURRENT_CATEGORIES = 'SET_CURRENT_CATEGORIES';
export const GET_FILTER_REST = 'GET_FILTER_REST';
export const GET_PRICE_FILTER = 'GET_PRICE_FILTER';
export const GET_MAX_PRICE_REST = 'GET_MAX_PRICE_REST';
export const GET_MAX_PRICE_REST_SEARCH_PAGE = 'GET_MAX_PRICE_REST_SEARCH_PAGE';
export const UPDATE_CATALOG_PRICE = 'UPDATE_CATALOG_PRICE';
export const SET_DEFAULT_PRICE_REST = 'SET_DEFAULT_PRICE_REST';
export const SET_PAGINATION_REST = 'SET_PAGINATION_REST';
export const SELECT_SORT_REST = 'SELECT_SORT_REST';
export const SET_PRODUCTGROUP_ID = 'SET_PRODUCTGROUP_ID';
export const SET_SEGMENT_ID = 'SET_SEGMENT_ID';
export const SET_SUB_SUB_ID = 'SET_SUB_SUB_ID';
export const SET_REST_FILTER_PROPERTY = 'SET_REST_FILTER_PROPERTY';
export const SET_OFFSET_REST = 'SET_OFFSET_REST';
export const GET_RESULT_SEARCH_REST = 'GET_RESULT_SEARCH_REST';
export const SET_EMPTY_PROPERTY = 'SET_EMPTY_PROPERTY';
export const SET_EMPTY_OFFSET = 'SET_EMPTY_OFFSET';
export const SET_EMPTY_FILTER = 'SET_EMPTY_FILTER';
export const SET_EMPTY_SORT = 'SET_EMPTY_SORT';
export const SET_EMPTY_MAX_PRICE = 'SET_EMPTY_MAX_PRICE';
export const SET_CATEGORY_LEVEL = 'SET_CATEGORY_LEVEL';
