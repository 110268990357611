const Home = {
    customerService: 'Klantendienst',
    nowOpen: '',
    nowClosed: '',
    csPhoneNL: '050 15 30 06',
    csPhoneFR: '026 16 57 42',
    serviceTimes: 'Beschikbaar van {{start}} tot {{end}}',
    recommendedProductsOffice: 'Aanbevolen Kantoorartikelen',
    recommendedProductsGadgets: 'Aanbevolen Gadgets',
    discoverOurCategories: 'Ontdek onze categorieën',
    customizedOffer: 'Aangepast aanbod',
    orCustomQuote: 'of een offerte op maat?',
    feedBackTimeHome: 'Antwoord binnen 24 uur (Enkel op werkdagen)',
    discover: 'Ontdekken',
    ourFavorites: 'Onze favorieten',
    buy: 'Bestellen',
    popularBlock1: 'Paraplu\'s',
    popularBlock2: 'High Tech',
    popularBlock3: 'Zakken',
    opOffreSurMesure: {
        text: 'Gadgets op maat',
        link: 'offerte-op-maat',
    },
    OfficeOffreSurMesure: {
        text: 'Gadgets op maat',
        link: 'vraag-uw-offerte-aan',
    },
    ecologicProducts: {
        text: 'Ontdek onze gamma van eco-producten bij Flyer.be',
        link: 'eco-producten',
    },
    corporateGifts: {
        text: 'Relatiegeschenken voor het einde van het jaar en nieuwjaar',
        link: 'relatiegeschenken-eindejaar-nieuwjaar',
    },
    subscribeNewsletter: {
        text: 'Abonneer u op onze Advertising Flyer-nieuwsbrief',
        link: 'inschrijven-nieuwsbrief-relatiegeschenken',
    },
    subscribeNewsletterOffice: {
        text: 'Abonneer u op onze Flyer Office-nieuwsbrief',
        link: 'inschrijven-nieuwsbrief-office',
    },
    lpSalons: {
        text: 'Relatiegeschenken en goodies voor de beurze',
        link: 'vakbeurs-promotieartikelen',
    },
};

export default Home;
