import { combineReducers } from 'redux';
import layout from './layoutReducer';
import catalog from './catalogReducer';
import home from './homeReducer';
import productPartner from './productPartnerReducer';
import landingPage from './landingPageReducer';

const reducer = combineReducers({
    layout,
    catalog,
    home,
    productPartner,
    landingPage,
});

export default reducer;
