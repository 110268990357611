import { Component } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import LogoGadgetsFR from '@src/assets/images/logo_gadgets_befr.png';
import LogoGadgetsNL from '@src/assets/images/logo_gadgets_benl.png';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
        if (process.env.NEXT_PUBLIC_NO_SENTRY) {
            throw new Error(JSON.stringify({ error, errorInfo }));
        }
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <div>
                    <Head>
                        <title>Sentry Onboarding</title>
                        <meta name="description" content="Make your Next.js ready for Sentry" />
                    </Head>

                    <main className="error">
                        <header>
                            <Image
                                src={LogoGadgetsFR}
                                layout="intrinsic"
                                alt={`Flyer.be ${process.env.NEXT_PUBLIC_SHOP_NAME}`}
                            />

                            <Image
                                src={LogoGadgetsNL}
                                layout="intrinsic"
                                alt={`Flyer.be ${process.env.NEXT_PUBLIC_SHOP_NAME}`}
                            />
                        </header>

                        <h1 className="error__title">OOps! there is something wrong</h1>
                        <p>An error has occurred, please reload the page or return to the homepage</p>

                        <button type="button" className="error__button" onClick={() => { window.location = '/'; }}>Go home</button>
                    </main>

                    <style global jsx>{`
                        .error {
                            min-height: 100vh;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }

                        .error > header {
                            margin: 0 0 2rem;
                        }

                        .error__title {
                            color: #dc3545;
                            font: 7OO 6rem Arial;
                        }

                        .error__button {
                            padding: 12px;
                            border-radius: 10px;
                            background-color: #c73852;
                            color: #fff;
                            transition: all .2s ease-in-out;
                        }

                        .error__button:hover {
                            background-color: #dc3545;
                        }
                    `}
                    </style>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
