/* eslint-disable @next/next/next-script-for-ga */
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl-be';
import { LOAD_GTAG_SCRIPTS } from '@src/utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import wrapper from '@src/redux/store';
import ProgressBar from '@src/components/ProgressBar';
import ErrorBoundary from '@src/components/ErrorBoundary';
import I18n, { Translations } from '@src/locales';
import { THEME } from '@src/assets/styles/settings.styled';
import GlobalStyle from '@src/assets/styles/global.styled';
import FontFaceStyle from '@src/assets/styles/fontface.styled';
import FlyerIconsStyle from '@src/assets/styles/flyericons.styled';
import 'bootstrap/dist/css/bootstrap.min.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const GTM_ID = 'GTM-T6XH5SQ';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'preprd') {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

axios.interceptors.request.use((requestConfig) => {
    const config = requestConfig;

    config.headers = {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_TOKEN}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return config;
}, (error) => Promise.reject(error));

const IS_DEV = ['preprd', 'develop'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT);

const App = ({ Component, pageProps, router }) => {
    const getLayout = Component.getLayout || function p(page) { return page; };

    useEffect(() => {
        const dayjsLocales = {
            benl: 'nl-be',
            befr: 'fr',
        };

        dayjs.locale(dayjsLocales[router.locale]);
    }, [router.locale]);

    useEffect(() => {
        if (LOAD_GTAG_SCRIPTS) {
            TagManager.initialize({ gtmId: GTM_ID });
        }
    }, []);

    const { store } = wrapper.useWrappedStore(pageProps);

    return (
        <>
            <Head>
                <meta key="robots" name="robots" content={IS_DEV ? 'noindex,nofollow' : 'index,follow'} />
                <meta key="googlebot" name="googlebot" content={IS_DEV ? 'noindex,nofollow' : 'index,follow'} />
                <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
                <meta name="google-site-verification" content="qLrtJKSpJnAnE9U_6qUX7bpurv9ZuQf3ytDMBXV6C5M" />
                <meta name="theme-color" content={THEME[shop].primary} />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="manifest" href="/manifest.json" />
                <link id="favicon" rel="icon" type="image/x-icon" href={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/favicons/favicon-${shop}.ico`} />
                <link rel="preconnect" href={process.env.NEXT_PUBLIC_FLYER} crossOrigin="" />
                <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_DRUPAL} crossOrigin="" />
                <link rel="preconnect" href={process.env.NEXT_PUBLIC_CDN_HOST} crossOrigin="" />
                <link rel="preconnect" href="//widget.trustpilot.com" />
                {['eot', 'ttf', 'woff', 'svg'].map((extension) => (
                    <link
                        key={extension}
                        rel="preload"
                        href={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.${extension}`}
                        as="font"
                        crossOrigin=""
                    />
                ))}
            </Head>

            <FontFaceStyle />
            <GlobalStyle />
            <ProgressBar height={2} startPosition={0.3} stopDelayMs={200} options={{ showSpinner: false }} />
            <FlyerIconsStyle />
            <ErrorBoundary>
                <I18n
                    locale={router.locale}
                    lngDict={{
                        ...Translations[router.locale],
                        ...(pageProps.translation || {}),
                    }}
                >
                    <ThemeProvider theme={{}}>
                        <Provider store={store}>{getLayout(<Component {...pageProps} />)}</Provider>
                    </ThemeProvider>
                </I18n>
            </ErrorBoundary>

            <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" defer strategy="lazyOnload" type="text/partytown" />
        </>
    );
};

console.log(`%c${shop}.flyer.be 👹`, `color:#fff; border-radius: 10px; background-color: ${THEME[shop].primary}; font-size: 20px; padding:10px; text-align: center; display: block;`);
console.log(process.env.NEXT_PUBLIC_ENVIRONMENT);

export default App;
