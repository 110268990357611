import { createGlobalStyle } from 'styled-components';
import { FONT_ICON } from './settings.styled';

/* import FONTFB_TTF from '../fonts/flyericons/flyericons.ttf';
import FONTFB_SVG from '../fonts/flyericons/flyericons.svg';
import FONTFB_WOFF from '../fonts/flyericons/flyericons.woff';
import FONTFB_EOT from '../fonts/flyericons/flyericons.eot'; */

const FlyerIconsStyle = createGlobalStyle`
    @font-face {
        font-family: '${FONT_ICON}';
        src: local('${FONT_ICON}'), url('${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.eot');
        src: url('${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.eot#iefix') format('embedded-opentype'),
            url('${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.ttf') format('truetype'),
            url('${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.woff') format('woff'),
            url('${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/fonts/flyericons/flyericons.svg#${FONT_ICON}') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    [class^="i-"], [class*=" i-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: '${FONT_ICON}' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .i-headphones:before {
      content: "\\e90e";
    }
    .i-shop:before {
      content: "\\e90f";
    }
    .i-box:before {
      content: "\\e907";
    }
    .i-cart:before {
      content: "\\e908";
    }
    .i-chat:before {
      content: "\\e909";
    }
    .i-envelope:before {
      content: "\\e90a";
    }
    .i-user:before {
      content: "\\e90b";
    }
    .i-call:before {
      content: "\\e90c";
    }
    .i-close:before {
      content: "\\e905";
    }
    .i-flag:before {
      content: "\\e910";
    }
    .i-search:before {
      content: "\\e906";
    }
    .i-star:before {
      content: "\\f005";
    }
    .i-star-o:before {
      content: "\\f006";
    }
    .i-linkedin-square:before {
      content: "\\f08c";
    }
    .i-twitter:before {
      content: "\\f099";
    }
    .i-facebook:before {
      content: "\\f09a";
    }
    .i-facebook-f:before {
      content: "\\f09a";
    }
    .i-linkedin:before {
      content: "\\f0e1";
    }
    .i-star-half-empty:before {
      content: "\\f123";
    }
    .i-star-half-full:before {
      content: "\\f123";
    }
    .i-star-half-o:before {
      content: "\\f123";
    }
    .i-instagram:before {
      content: "\\f16d";
    }
    .i-facebook-official:before {
      content: "\\f230";
    }
    .i-pinterest-p:before {
      content: "\\f231";
    }
    .i-whatsapp:before {
      content: "\\f232";
    }
    .i-arrow-up:before {
      content: "\\e900";
    }
    .i-check:before {
      content: "\\e90d";
    }
    .i-chevron-down:before {
      content: "\\e901";
    }
    .i-chevron-left:before {
      content: "\\e902";
    }
    .i-chevron-right:before {
      content: "\\e903";
    }
    .i-chevron-up:before {
      content: "\\e904";
    }
    .i-minus:before {
      content: "\\e912";
    }
    .i-minus-circle:before {
      content: "\\e913";
    }
    .i-minus-square:before {
      content: "\\e914";
    }
    .i-plus:before {
      content: "\\e915";
    }
    .i-plus-circle:before {
      content: "\\e916";
    }
    .i-plus-square:before {
      content: "\\e917";
    }
    .i-refresh:before {
      content: "\\e911";
    }
`;

export default FlyerIconsStyle;
