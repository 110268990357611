export const SET_SEARCH_FIELD = 'SET_SEARCH_FIELD';
export const SET_MOBILE_MENU_STATUS = 'SET_MOBILE_MENU_STATUS';
export const SET_MOBILE_CATEGORY_STATUS = 'SET_MOBILE_CATEGORY_STATUS';
export const GET_SESSION = 'GET_SESSION';
export const INCREMENT_TOTAL_CART = 'INCREMENT_TOTAL_CART';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_NATIVE_DATA = 'SET_NATIVE_DATA';
export const SET_MENU_BACKGROUND = 'SET_MENU_BACKGROUND';
export const SET_LOCALE_URLS = 'SET_LOCALE_URLS';
export const SET_TOP_URL_TYPE = 'SET_TOP_URL_TYPE';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
