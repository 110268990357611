const Header = {
    hello: 'Bonjour {{user}}',
    login: 'Me connecter',
    cart: 'Panier',
    cartLink: 'panier',
    all: 'Tout {{segment}}',
    allCategories: 'Tous les produits',
    loading: 'Chargement en cours...',
    Home: 'Accueil',
    Office: 'Office',
    Gadgets: 'Objets publicitaires',
    myAccount: {
        text: 'Mon compte',
        link: 'myprintconnect/profile',
    },
    myAdresses: {
        text: 'Mes adresses',
        link: 'myprintconnect/addresses_book',
    },
    myOrders: {
        text: 'Mes commandes',
        link: 'myprintconnect/orders',
    },
    myBills: {
        text: 'Mes factures',
        link: 'myprintconnect/invoices',
    },
    signOut: 'Déconnexion',
    unbeatablePrices: 'Souhaitez-vous bénéficiez de nos prix imbattables ?',
    lowPrices: 'Prix bas',
    printedInBelgium: 'Imprimé en Belgique',
    quality: 'Qualité',
    lang: 'Langue',
};

export default Header;
