import { actionCreator } from '@src/utils';
import { HYDRATE } from 'next-redux-wrapper';
import * as CatalogTypes from '../types/catalogTypes';

const initState = {
    restFilter: {
        product_group: null,
        segment_id: null,
        sub_id: null,
        price: [],
        maxPrice: null,
        limit: 9,
        offset: 0,
        sort: null,
        properties: {},
    },
    filters: {
        loading: false,
        error: null,
        data: null,
        price: [],
    },
    products: {
        loading: false,
        error: null,
        data: null,
        level: 0,
        categories: [],
    },
    url: '',
    resultSearch: {
        loading: false,
        error: null,
        data: null,
    },
};

export default function catalog(state = initState, action) {
    switch (action.type) {
    case HYDRATE:
        return { ...state };

    case actionCreator(CatalogTypes.GET_FILTER_REST, 'req'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: true,
                error: null,
            },
        };

    case actionCreator(CatalogTypes.GET_FILTER_REST, 'res'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(CatalogTypes.GET_FILTER_REST, 'err'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'req'):
        return {
            ...state,
            products: {
                ...state.products,
                loading: true,
                error: null,
            },
        };

    case actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'res'):
        return {
            ...state,
            products: {
                ...state.products,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'err'):
        return {
            ...state,
            products: {
                ...state.products,
                loading: false,
                error: action.error,
                data: null,
            },
        };

    case CatalogTypes.SET_ORIGINAL_DATA:
        return {
            ...state,
            url: action.url,
            products: {
                ...state.products,
                loading: false,
                data: null,
            },
            restFilter: {
                ...state.restFilter,
                ...initState.restFilter,
            },
            filters: {
                ...state.filters,
                ...initState.filters,
            },
        };

    case CatalogTypes.SET_CURRENT_CATEGORIES:
        return {
            ...state,
            products: {
                ...state.products,
                categories: action.payload,
            },
        };

    case actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'req'):
        return {
            ...state,
            resultSearch: {
                ...state.resultSearch,
                loading: true,
                error: null,
            },
        };

    case actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'res'):
        return {
            ...state,
            resultSearch: {
                ...state.resultSearch,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'err'):
        return {
            ...state,
            resultSearch: {
                ...state.resultSearch,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case CatalogTypes.SET_REST_FILTER_PROPERTY:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                properties: {
                    ...state.restFilter.properties,
                    [action.originalName]: action.value,
                },
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'req'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: true,
                error: null,
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'res'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: false,
            },
            restFilter: {
                ...state.restFilter,
                maxPrice: action.data.maxpriceProduct.max_min_price,
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'err'):
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: false,
                error: action.error,
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'req'):
        return {
            ...state,
            resultSearch: {
                ...state.resultSearch,
                loading: true,
                error: null,
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'res'):
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                maxPrice: action.data.maxpriceSearchPage?.max_min_price,
            },
        };

    case actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'err'):
        return {
            ...state,
            resultSearch: {
                ...state.resultSearch,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case CatalogTypes.UPDATE_CATALOG_PRICE:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                price: action.price,
            },
        };

    case CatalogTypes.SET_DEFAULT_PRICE_REST:
        return {
            ...state,
            filters: {
                ...state.filters,
                price: action.price,
            },
        };

    case CatalogTypes.SET_PAGINATION_REST:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                offset: (action.page - 1) * state.restFilter.limit,
            },
        };

    case CatalogTypes.SET_OFFSET_REST:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                offset: action.offset,
            },
        };

    case CatalogTypes.SELECT_SORT_REST:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                sort: action.sort,
            },
        };

    case CatalogTypes.SET_PRODUCTGROUP_ID:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                product_group: action.subCatalog,
                // sort: null,
                offset: action.page ? action.page : 0,
            },
        };

    case CatalogTypes.SET_SUB_SUB_ID:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                sub_id: action.sub,
                // sort: null,
                offset: action.page ? action.page : 0,
            },
        };

    case CatalogTypes.SET_SEGMENT_ID:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                segment_id: action.segment,
                // sort: null,
                offset: action.page ? action.page : 0,
            },
        };

    case CatalogTypes.SET_EMPTY_PROPERTY:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                properties: {},
            },
        };

    case CatalogTypes.SET_EMPTY_FILTER:
        return {
            ...state,
            filters: {
                ...state.filters,
                data: null,
                price: [],
            },
        };

    case CatalogTypes.SET_EMPTY_OFFSET:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                offset: 0,
            },
        };

    case CatalogTypes.SET_EMPTY_SORT:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                sort: null,
            },
        };

    case CatalogTypes.SET_EMPTY_MAX_PRICE:
        return {
            ...state,
            restFilter: {
                ...state.restFilter,
                maxPrice: null,
                price: [],
            },
        };

    case CatalogTypes.SET_CATEGORY_LEVEL:
        return {
            ...state,
            products: {
                ...state.products,
                level: action.level,
            },
        };

    default:
        return state;
    }
}
