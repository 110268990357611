const Footer = {
    flyerCS: 'Service client Flyer',
    alwaysListening: 'Toujours à votre écoute',
    infoMail: '{{reseller}}@flyer.be',
    feedBackTime: 'Un retour en 24 heures (Sauf le weekend)',
    chatWithUs: 'Chattez avec nous',
    chatFeedBackTime: 'On vous répond en 5 minutes',
    help: {
        text: 'Aide',
        link: 'aide',
    },
    frequentlyAskedQuestions: {
        text: 'Questions fréquentes',
        link: 'les-questions-fr%C3%A9quemment-pos%C3%A9es',
    },
    myCommand: {
        text: 'Ma commande',
        link: 'ma-commande',
    },
    fileReadyForPrint: {
        text: 'Un fichier prêt pour l\'impression',
        link: 'fournir-un-fichier-conforme-%C3%A0-impression',
    },
    myFileControl: {
        text: 'Contrôle de mon fichier',
        link: 'contr%C3%B4le_de_mon_fichier',
    },
    orderAgain: {
        text: 'Commander à nouveau',
        link: 'repasser-une-commande',
    },
    forgotPassword: {
        text: 'Mot de passe oublié',
        link: 'forgotpassword?return=myprintconnect',
    },
    contactUs: {
        text: 'Contactez-nous',
        link: 'contact/general',
    },
    orderProcess: {
        text: 'Processus de commandes',
        link: 'processus-de-commandes',
    },
    satisfied: {
        text: 'Satisfait ou satisfait',
        link: 'satisfait-ou-satisfait',
    },
    whoAreWe: {
        text: 'Qui sommes-nous',
        link: 'qui-sommes-nous',
    },
    ourServices: {
        text: 'Nos services',
        link: 'nos-services',
    },
    garantie: {
        text: 'Garantie',
        link: 'garantie-fr',
    },
    aboutFlyer: {
        text: 'A propos',
        link: 'a-propos-de-impression-flyerbe',
    },
    aboutFlyerOffice: {
        text: 'Flyer Office',
    },
    personalDataAndCookies: {
        text: 'Données personnelles et cookies',
        link: 'donnees-personnelles-et-cookies',
    },
    contactUsOffice: {
        text: 'Contactez-nous',
        link: 'contactez-nous',
    },
    faq: {
        text: 'FAQ',
        link: 'les-questions-frequemment-posees',
    },
    placeAnOrder: {
        text: 'Passez une commande',
        link: 'passez-une-commande',
    },
    deliveryOrder: {
        text: 'Livraison',
        link: 'renseignements-ordre-general',
    },
    returnPolicy: {
        text: 'Politique de retour',
        link: 'politique-de-retour',
    },
    conditionsOfReturn: {
        text: 'Conditions de retour',
        link: 'conditions-de-retour',
    },
    quotationRequest: {
        text: 'Demande de devis',
        link: 'demande-de-devis',
    },
    flyerstoresServicePoints: {
        text: 'Flyerstores & Points de service',
        link: 'flyerstores',
    },
    brusselsStore: {
        text: 'Bruxelles',
        link: 'flagshipstore-bruxelles',
    },
    brugesStore: {
        text: 'Bruges',
        link: 'imprimerie-bruges',
    },
    blog: {
        text: 'Blog',
        link: 'blog',
    },
    novetly: {
        text: 'Nouveautés',
        link: 'blogpost/cat/416',
    },
    ourProducts: {
        text: 'Produits',
        link: 'nos-produits',
    },
    popularProducts: {
        text: 'Produits populaires',
        link: 'qualit%C3%A9-impressions',
    },
    stickers: {
        text: 'Autocollants',
        link: 'imprimer-autocollants',
    },
    largeFormats: {
        text: 'Grands formats',
        link: 'impressions-grands-formats',
    },
    flags: {
        text: 'Drapeaux',
        link: 'drapeaux-imprimer',
    },
    photoProducts: {
        text: 'Produits photos',
        link: 'produits-photos-imprimer',
    },
    freeTemplate: {
        text: 'Modèle Gratuit',
        link: 'templates',
    },
    freeSampleFlip: {
        text: 'Sample Flip GRATUIT',
        link: 'sampleflip',
    },
    graphicTermsAZ: {
        text: 'Les termes graphiques de A à Z',
        link: 'termes-graphiques-difficiles',
    },
    cmykColors: {
        text: 'Couleurs CMJN',
        link: 'couleurs-les-couleurs-CMJN-RVB-et-Pantone-PMS',
    },
    overflowOrOverflow: {
        text: 'Débord ou débordement',
        link: 'debord',
    },
    resolutionOrDPI: {
        text: 'Résolution ou DPI',
        link: 'dpi-ou-ppp',
    },
    finishes: {
        text: 'Finitions',
        link: 'finitions-impressions',
    },
    conditions: {
        text: 'Autocollants',
        link: 'imprimer-autocollants',
    },
    salesTermsAndConditions: {
        text: 'Conditions générales de ventes',
        link: 'conditions-g%C3%A9n%C3%A9rales-de-vente',
    },
    legalNotice: {
        text: 'Mentions légales',
        link: 'mentions-l%C3%A9gales',
    },
    deliveryAndWithdrawal: {
        text: 'Livraison et retrait',
        link: 'livraison-et-retrait',
    },
    pricesAreHT: 'Tous les prix mentionnés sont HT, sauf mention contraire.',
    privacyPolicy: {
        text: 'Charte de confidentialité',
        link: 'charte-de-confidentialite',
    },
    CancelOrder: {
        text: 'Annuler ma commande',
        link: 'retracter-ma-commande',
    },
    Cookies: {
        text: 'En savoir plus',
        link: 'politique-sur-les-cookies',
    },
    CookiesText: {
        text: "Flyer.be utilise des cookies pour améliorer l'expérience d'utilisateur. En poursuivant votre navigation, vous acceptez l’utilisation de Cookies.",
    },
    CookiesValide: {
        text: 'OK, je comprends',
    },
    securePayment: 'Paiement sécurisé avec :',
    ourDeliveryMethods: 'Nos méthodes de livraison',
    siteMap: {
        text: 'Plan du site',
        link: 'sitemap',
    },
    followUs: 'Suivez-nous !',
    stayInformedOfOurLatestOffers: 'Restez au courant de nos dernières offres !',
    SubscribeToOurNewsletter: 'Inscrivez-vous à notre newsletter !',
    clients: 'Clients',
    convenient: 'Pratique',
    assistance: 'Assistance',
    ourCollection: 'Notre collection',

    clothing: {
        text: 'Hauts',
        link: 'hauts/',
    },
    bags: {
        text: 'Sacs',
        link: 'voyages-bagagerie/',
    },
    pens: {
        text: 'Stylos',
        link: 'stylos/',
    },
    cupsAndGlasses: {
        text: 'Tasses et verres',
        link: 'bouteilles/',
    },
    sportsAndLeisures: {
        text: 'Sports et loisirs',
        link: 'loisirs-et-golf/',
    },
    officeItems: {
        text: 'Articles de bureau',
        link: 'bureau/',
    },
    houseAndWellBeing: {
        text: 'Maison et bien-être',
        link: 'sante-et-bien-etre/',
    },
    carsAndTools: {
        text: 'Voitures et outils',
        link: 'outils-et-lampes/accessoires-voiture/',
    },
    umbrellas: {
        text: 'Parapluies',
        link: 'parapluies_beabd139-e322-48b3-9c18-36eb3ae89533',
    },
    soundVision: {
        text: 'Audio & Vidéo',
        link: 'audio-video_02e9330d-007f-4b29-80f6-0698e24089ac',
    },
    technology: {
        text: 'Technologie',
        link: 'technologie/',
    },
    bagsTravel: {
        text: 'Voyages & Bagagerie',
        link: 'voyages-bagagerie_a16ca3a0-af4e-424c-8a69-18ded886509d',
    },
    officeBusiness: {
        text: 'Bureau',
        link: 'bureau_de9315f3-9609-4127-b825-068cd696ab8d',
    },
    healthPersonalCare: {
        text: 'Santé et bien-être',
        link: 'sante-et-bien-etre_ae14f7b1-9e3d-4e6e-be88-2895d3a5dcc9',
    },
    safetyFirstAid: {
        text: 'Sécurité & premiers soins',
        link: 'securite-premiers-soins_bbbc4773-79d9-4bd6-9340-e1055f7bd3d4',
    },
    officeSupplies: {
        text: 'Fournitures de bureau',
        link: 'fournitures-de-bureau/fournitures-de-bureau',
    },
    officeFurniture: {
        text: 'Mobilier de bureau',
        link: 'services-generaux-poste-de-travail/mobilier-de-bureau',
    },
    filingArchiving: {
        text: 'Classement & Archivage',
        link: 'classement',
    },
    mailShipping: {
        text: 'Courrier & Expédition',
        link: 'traitement-du-courrier-expedition',
    },
    computerHighTech: {
        text: 'Informatique & High Tech',
        link: 'ict-numerique',
    },
    cartridgesToners: {
        text: 'Cartouches & toners',
        link: 'toners-encre',
    },
    generalServices: {
        text: 'Services généraux',
        link: 'services-generaux-poste-de-travail',
    },
};

export default Footer;
