import * as LayoutTypes from '../types/layoutTypes';
import { actionCreator } from '../../utils';

export const initState = {
    cookie: '',
    cart: {
        list: [],
        count: 0,
        loading: false,
    },
    profile: null,
    search: '',
    menuOpened: false,
    submenu: [],
    selectedMobileCategory: '',
    menuBackground: false,
    urls: {},
    topUrlType: 'link',
    breadcrumbs: [],
};

export default function layout(state = initState, action) {
    switch (action.type) {
    case LayoutTypes.SET_LOCALE_URLS:
        return { ...state, urls: action.payload };

    case LayoutTypes.SET_TOP_URL_TYPE:
        return { ...state, topUrlType: action.payload };

    case LayoutTypes.SET_BREADCRUMBS:
        return { ...state, breadcrumbs: action.payload };

    case LayoutTypes.SET_SEARCH_FIELD:
        return { ...state, search: action.search };

    case LayoutTypes.SET_MOBILE_MENU_STATUS:
        return { ...state, menuOpened: action.status };

    case LayoutTypes.SET_MOBILE_CATEGORY_STATUS:
        return { ...state, selectedMobileCategory: action.category };

    case LayoutTypes.SET_MENU_BACKGROUND:
        return { ...state, menuBackground: action.menuBg };

    case actionCreator(LayoutTypes.GET_SESSION, 'req'):
        return {
            ...state,
            cart: {
                ...state.cart,
                loading: true,
            },
        };

    case actionCreator(LayoutTypes.GET_SESSION, 'res'):
        return {
            ...state,
            cart: {
                ...state.cart,
                count: action.data.cart,
                loading: false,
            },
            profile: action.data.profile,
            cookie: action.data.cookie,
        };

    case actionCreator(LayoutTypes.SET_LOGOUT, 'req'):
        return {
            ...state,
            cart: { ...state.cart, loading: true },
        };

    case actionCreator(LayoutTypes.SET_LOGOUT, 'res'):
        return {
            ...state,
            cart: {
                ...state.cart,
                count: 0,
                loading: false,
            },
            profile: null,
            cookie: action.data.cookie,
        };

    case actionCreator(LayoutTypes.SET_LOGOUT, 'err'):
        return {
            ...state,
            loading: false,
            error: true,
        };

    case LayoutTypes.INCREMENT_TOTAL_CART:
        return {
            ...state,
            cart: {
                ...state.cart,
                count: state.cart.count + 1,
            },
        };

    case LayoutTypes.SET_NATIVE_DATA:
        return {
            ...state,
            loading: false,
            submenu: action.data.submenu,
            cookie: action.data.cookie,
            native: true,
        };

    default:
        return state;
    }
}
