const Catalog = {
    productsFound: 'produits trouvés',
    productsFound_1: 'produit trouvé',
    sortBy: 'Trier par',
    select: 'Sélectionner',
    ascendingPrice: 'Prix Ascendant',
    descendingPrice: 'Prix Desendant',
    nameAZ: 'Nom A-Z',
    nameZA: 'Nom Z-A',
    productsPerPage: 'Produits par page',
    next: 'Suivant',
    previous: 'Précedent',
    first: 'Premier',
    last: 'Dernier',
    colors: 'Couleurs',
    color: 'Couleur',
    brand: 'Marque',
    price: 'Prix',
    filter: 'Filtres',
    Go: 'Filtrer',
    category: 'Catégorie',
    noProduct: 'Aucun produit trouvé',
    FindProduct: 'Rechercher une marque, un produit, ...',
    search: 'Recherche',
    Result_of_your_search: 'Résultat du produit',
    Sorry_not_find: "Malheureusement, nous n'avons trouvé aucun produit correspondant à",
    TryAgain: "Essayez à nouveau en vérifiant l'orthographe ou en faisant une recherche plus courte.",
    noProductsFound: 'Aucun produit trouvé',
    ClearYourSelection: 'Effacer votre sélection',
};

export default Catalog;
