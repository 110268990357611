import * as HomeTypes from '../types/homeTypes';
import { actionCreator } from '../../utils';

const initState = {
    banner: {
        loading: false,
        error: null,
        data: null,
        native: false,
    },
    popular: {
        loading: false,
        error: null,
        data: null,
    },
    recommended: {
        loading: false,
        error: null,
        data: null,
    },
    promobanner: {
        loading: false,
        error: null,
        list: [
            {
                link: 'https://d327kdaxjd7x69.cloudfront.net/front/images/home/promobanner1.png',
                title: 'Banner 1',
            },
            {
                link: 'https://d327kdaxjd7x69.cloudfront.net/front/images/home/promobanner2.png',
                title: 'Banner 2',
            },
        ],
    },
};

export default function home(state = initState, action) {
    switch (action.type) {
    case actionCreator(HomeTypes.FETCH_RECOMMENDED_INFO, 'req'):
        return {
            ...state,
            recommended: {
                ...state.recommended,
                loading: true,
                error: null,
            },
        };

    case actionCreator(HomeTypes.FETCH_RECOMMENDED_INFO, 'res'):
        return {
            ...state,
            recommended: {
                ...state.recommended,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(HomeTypes.FETCH_RECOMMENDED_INFO, 'err'):
        return {
            ...state,
            recommended: {
                ...state.recommended,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case actionCreator(HomeTypes.FETCH_POPULAR_INFO, 'req'):
        return {
            ...state,
            popular: {
                ...state.popular,
                loading: true,
                error: null,
            },
        };

    case actionCreator(HomeTypes.FETCH_POPULAR_INFO, 'res'):
        return {
            ...state,
            popular: {
                ...state.popular,
                loading: false,
                data: action.data,
            },
        };

    case actionCreator(HomeTypes.FETCH_POPULAR_INFO, 'err'):
        return {
            ...state,
            popular: {
                ...state.popular,
                loading: false,
                error: action.error,
                data: [],
            },
        };

    case HomeTypes.SET_NATIVE_HOME:
        return {
            ...state,
            [action.key]: {
                ...state[action.key],
                data: action.data,
                native: true,
            },
        };

    default:
        return state;
    }
}
