import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import reducers from '../reducers';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state,
            ...action.payload,
        };

        if (nextState.catalog.url === state.catalog.url) {
            if (state.catalog.restFilter.sort) {
                nextState.catalog.restFilter.sort = state.catalog.restFilter.sort;
            }

            if (state.catalog.restFilter.price) {
                nextState.catalog.restFilter.price = state.catalog.restFilter.price;
            }

            if (state.catalog.filters.price) {
                nextState.catalog.filters.price = state.catalog.filters.price;
            }

            if (state.catalog.restFilter.offset > 0) {
                nextState.catalog.restFilter.offset = state.catalog.restFilter.offset;
            }

            nextState.catalog.products.data = state.catalog.products.data;

            if (Object.keys(state.catalog.restFilter.properties).length > 0) {
                nextState.catalog.restFilter.properties = state.catalog.restFilter.properties;
            }

            if (state.productPartner.recentlyseen.data) {
                nextState.productPartner.recentlyseen.data = state.productPartner.recentlyseen.data;
            }
        }

        return nextState;
    }

    return reducers(state, action);
};

const initStore = () => createStore(reducer, bindMiddleware([thunkMiddleware]));

const wrapper = createWrapper(initStore);

export default wrapper;
