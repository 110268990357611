const Error = {
    error404: 'Fout 404',
    description404: 'Pagina niet gevonden',
    return404: 'Terug naar de startpagina',
    error500: 'Fout 500',
    description500: 'Er is een serverfout opgetreden',
    return500: 'Terug naar de startpagina',
};

export default Error;
