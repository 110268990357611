import { createGlobalStyle } from 'styled-components';
import { FONT, THEME, ORANGE } from './settings.styled';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const GlobalStyle = createGlobalStyle`
    html, body {
        width: 100%;
        height: 100%;
        position: relative;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
    }

    :root {
        --primary: ${THEME[shop].primary};
        --secondary: ${THEME[shop].secondary};
        --normal: ${THEME[shop].normal};
        --light: ${THEME[shop].light};
        --dark: ${THEME[shop].dark};
        --danger: ${THEME[shop].danger};
        --success: ${THEME[shop].success};

        --lighter: ${THEME[shop].lighter};
        --lightness: ${THEME[shop].lightness};
        --medium: ${THEME[shop].medium};
        --dark-primary: ${THEME[shop].dark_primary};
        --dark-secondary: ${THEME[shop].dark_secondary};
        --orange: ${ORANGE};
    }

    #root {
        width: 100%;
        height: 100%;
    }
    
    body {
        color: var(--normal);
        font: 400 1em '${FONT}', Arial, sans-serif;
        min-width: 320px;
        width: 100%;
        height: 100%;
    }

    a {
        &:hover {
            color: var(--primary);
            text-decoration: none;
        }

        &:active, &:focus {
            outline: 0;
        }
    }
    
    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    button {
        border: 0;
        background: transparent;
        outline: 0;
        cursor: pointer;

        &:focus {
            outline: 0
        }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        appearance: none; 
        margin: 0; 
    }

    div[role="button"] {
        cursor: default;
    }

    input[type='number'] {
        appearance:textfield;
    }

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 56px;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: var(--light);
        z-index: 10000;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 5px 18px 0 rgba(155, 200, 242, 0.5);
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 10px 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #f0f3f6;
    }
`;

export default GlobalStyle;
