/* eslint-disable react/function-component-definition */
import { useEffect, useState, useContext, useRef, createContext } from 'react';
import rosetta from 'rosetta';
import befr from './befr/index';
import benl from './benl/index';

export const Translations = {
    befr,
    benl,
};

const i18n = rosetta();
const I18nContext = createContext({});

i18n.locale(process.env.NEXT_PUBLIC_DEFAULT_LOCALE);

export default function I18n({ children, locale, lngDict }) {
    const activeLocaleRef = useRef(locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE);
    const [, setTick] = useState(0);
    const firstRender = useRef(true);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const i18nWrapper = {
        activeLocale: activeLocaleRef.current,
        t: (...args) => i18n.t(args[0], args[1], args[2]),
        locale: (l, dict) => {
            i18n.locale(l);
            activeLocaleRef.current = l;
            if (dict) {
                i18n.set(l, dict);
            }

            setTick((tick) => tick + 1);
        },
    };

    if (locale && firstRender.current === true) {
        firstRender.current = false;
        i18nWrapper.locale(locale, lngDict);
    }

    useEffect(() => {
        if (locale) {
            i18nWrapper.locale(locale, lngDict);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lngDict, locale]);

    return (
        <I18nContext.Provider value={i18nWrapper}>{children}</I18nContext.Provider>
    );
}

export const useI18n = () => useContext(I18nContext);
