const Footer = {
    flyerCS: 'Klantendienst',
    alwaysListening: 'Speciaal voor jou!',
    infoMail: '{{reseller}}@flyer.be',
    feedBackTime: 'Antwoord binnen 24 uur (Enkel op werkdagen)',
    chatWithUs: 'Chat met ons',
    chatFeedBackTime: 'Antwoord binnen 5 minuten',
    help: {
        text: 'Help',
        link: 'help',
    },
    frequentlyAskedQuestions: {
        text: 'Veelgestelde vragen',
        link: 'de-meest-gestelde-vragen',
    },
    myCommand: {
        text: 'Mijn bestelling',
        link: 'mijn-bestelling',
    },
    fileReadyForPrint: {
        text: 'Een drukklaar bestand aanleveren',
        link: 'een-drukklaar-bestand-leveren',
    },
    myFileControl: {
        text: 'Controle van mijn bestand',
        link: 'controle_van_mijn_bestand',
    },
    orderAgain: {
        text: 'Opnieuw bestellen',
        link: 'herbestellen',
    },
    forgotPassword: {
        text: 'Wachtwoord vergeten ?',
        link: 'forgotpassword?return=myprintconnect',
    },
    contactUs: {
        text: 'Neem contact op',
        link: 'contact/general',
    },
    orderProcess: {
        text: 'Bestelproces',
        link: 'bestelproces',
    },
    satisfied: {
        text: 'Tevreden of tevreden',
        link: 'tevreden-tevreden',
    },
    whoAreWe: {
        text: 'Wie zijn wij?',
        link: 'wie-zijn-wij',
    },
    ourServices: {
        text: 'Onze diensten',
        link: 'onze-diensten',
    },
    garantie: {
        text: 'Garantie',
        link: 'garantie-nl',
    },
    aboutFlyer: {
        text: 'Over ons',
        link: 'over-drukkerij-flyerbe',
    },
    aboutFlyerOffice: {
        text: 'Flyer Office',
    },
    personalDataAndCookies: {
        text: 'Privacy verklaring',
        link: 'privacy-verklaring',
    },
    contactUsOffice: {
        text: 'Neem contact op',
        link: 'contacteer-ons',
    },
    faq: {
        text: 'FAQ',
        link: 'veelgestelde-vragen',
    },
    placeAnOrder: {
        text: 'Een bestelling plaatsen',
        link: 'bestelling-plaatsen',
    },
    deliveryOrder: {
        text: 'Levering',
        link: 'algemene-informatie',
    },
    returnPolicy: {
        text: 'Retourneringsbeleid',
        link: 'retourneringsbeleid',
    },
    conditionsOfReturn: {
        text: 'Retourvoorwaarden',
        link: 'retourvoorwaarden',
    },
    quotationRequest: {
        text: 'Offerte aanvragen',
        link: 'offerte-aanvragen',
    },
    flyerstoresServicePoints: {
        text: 'Flyerstores & Servicepoints',
        link: 'flyerstores',
    },
    brusselsStore: {
        text: 'Brussel',
        link: 'flagshipstore-brussel',
    },
    brugesStore: {
        text: 'Brugge',
        link: 'drukkerij-brugge',
    },
    blog: {
        text: 'Blog',
        link: 'blog',
    },
    jobs: {
        text: 'Jobs',
        link: 'https://jobs.flyer.be/',
    },
    novetly: {
        text: 'Nieuws',
        link: 'blogpost/cat/416',
    },
    ourProducts: {
        text: 'Drukwerk',
        link: 'drukwerk',
    },
    popularProducts: {
        text: 'Meest besteld',
        link: 'kwalitatief-drukwerk',
    },
    stickers: {
        text: 'Stickers',
        link: 'stickers-bedrukken',
    },
    largeFormats: {
        text: 'Groot formaat',
        link: 'drukwerk-groot-formaat',
    },
    flags: {
        text: 'Vlaggen',
        link: 'vlaggen-bedrukken',
    },
    photoProducts: {
        text: 'Fotoproducten',
        link: 'fotoproducten-bedrukken',
    },
    tipsAndInspiration: {
        text: 'Tips en inspiratie',
        link: 'blogpost/cat/194',
    },
    freeTemplate: {
        text: 'Gratis layouts',
        link: 'layouts',
    },
    freeSampleFlip: {
        text: 'Gratis papierstalen',
        link: 'sampleflip',
    },
    graphicTermsAZ: {
        text: 'Grafische termen A-Z',
        link: 'moeilijke-grafische-termen',
    },
    cmykColors: {
        text: 'CMYK, wat is dat?',
        link: 'kleuren-cmyk-rgb-en-pantone-pms-kleuren',
    },
    overflowOrOverflow: {
        text: 'Afloop / Bleed',
        link: 'afloop-bleed',
    },
    resolutionOrDPI: {
        text: 'DPI resolutie',
        link: 'dpi',
    },
    finishes: {
        text: 'Afwerkingen drukwerk',
        link: 'afwerkingen-drukwerk',
    },
    productInformation: {
        text: 'Product informatie',
        link: 'blogpost/cat/193',
    },
    conditions: {
        text: 'Voorwaarden',
        link: 'algemene-verkoopsvoorwaarden',
    },
    salesTermsAndConditions: {
        text: 'Algemene verkoopsvoorwaarden',
        link: 'algemene-verkoopsvoorwaarden',
    },
    legalNotice: {
        text: 'Privacy policy',
        link: 'privacy-policy',
    },
    deliveryAndWithdrawal: {
        text: 'Levering en afhaling',
        link: 'levering-en-afhaling',
    },
    pricesAreHT: 'Alle prijzen zijn excl. BTW tenzij anders vermeld.',
    privacyPolicy: {
        text: 'Privacy',
        link: 'privacy-policy',
    },
    CancelOrder: {
        text: 'Herroepen van mijn bestelling',
        link: 'herroepen-van-mijn-bestelling',
    },
    Cookies: {
        text: 'Cookie policy',
        link: 'cookie-policy',
    },
    CookiesText: {
        text: 'Flyer.be maakt gebruik van cookies om de gebruikerservaring te verbeteren. Door verder te surfen ga je akkoord met deze cookies. Je leest er alles over in onze.',
    },
    CookiesValide: {
        text: 'OK, ik snap het',
    },
    securePayment: 'Veilige betaling met :',
    ourDeliveryMethods: 'Onze logistieke partners',
    siteMap: {
        text: 'Sitemap',
        link: 'sitemap',
    },
    followUs: 'Volg ons !',
    stayInformedOfOurLatestOffers: 'Blijf op de hoogte van onze nieuwste aanbiedingen !',
    SubscribeToOurNewsletter: 'Meld u aan voor onze nieuwsbrief !',
    convenient: 'Praktijk',
    clients: 'Clients',
    assistance: 'Bijstand',
    ourCollection: 'Onze collectie',

    clothing: {
        text: 'Tops',
        link: 'tops/',
    },
    bags: {
        text: 'Zakken',
        link: 'reizen-tassen/',
    },
    pens: {
        text: 'Balpennen',
        link: 'pennen/',
    },
    cupsAndGlasses: {
        text: 'Tassen en glazen',
        link: 'drinkwaren/',
    },
    sportsAndLeisures: {
        text: 'Sport en vrije tijd',
        link: 'vrije-tijd/',
    },
    officeItems: {
        text: 'Kantoorartikelen',
        link: 'kantoor-business/',
    },
    houseAndWellBeing: {
        text: 'Thuis en welzijn',
        link: 'gezondheid-en-welzijn/',
    },
    carsAndTools: {
        text: 'Wagen en gereedschap',
        link: 'gereedschap-en-lampen/accessoires-auto/',
    },
    umbrellas: {
        text: 'Paraplu-s',
        link: 'paraplus_beabd139-e322-48b3-9c18-36eb3ae89533',
    },
    soundVision: {
        text: 'Beeld & geluid',
        link: 'beeld-geluid_02e9330d-007f-4b29-80f6-0698e24089ac',
    },
    technology: {
        text: 'Technologie',
        link: 'technologie/',
    },
    bagsTravel: {
        text: 'Reizen & Tassen',
        link: 'reizen-tassen_a16ca3a0-af4e-424c-8a69-18ded886509d',
    },
    officeBusiness: {
        text: 'Kantoor & business',
        link: 'kantoor-business_de9315f3-9609-4127-b825-068cd696ab8d',
    },
    healthPersonalCare: {
        text: 'Gezondheid en verzorging',
        link: 'gezondheid-en-verzorging_ae14f7b1-9e3d-4e6e-be88-2895d3a5dcc9',
    },
    safetyFirstAid: {
        text: 'Veiligheid & Eerste hulp',
        link: 'veiligheid-eerste-hulp_bbbc4773-79d9-4bd6-9340-e1055f7bd3d4',
    },
    officeSupplies: {
        text: 'Bureau-accessoires',
        link: 'kantoorartikelen/bureau-accessoires',
    },
    officeFurniture: {
        text: 'Kantoormeubels',
        link: 'facilitair-en-werkplek/kantoormeubels',
    },
    filingArchiving: {
        text: 'Opbergen en archiveren',
        link: 'opbergen',
    },
    mailShipping: {
        text: 'Post en Verzenden',
        link: 'post-en-verzenden',
    },
    computerHighTech: {
        text: 'Computer & High Tech',
        link: 'ict-en-digitaal',
    },
    cartridgesToners: {
        text: 'Cartridges & Toners',
        link: 'toners-en-inkt',
    },
    generalServices: {
        text: 'Facilitair',
        link: 'facilitair-en-werkplek',
    },
};

export default Footer;
