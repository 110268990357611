const Error = {
    error404: 'Erreur 404',
    description404: 'Page non trouvé',
    return404: 'Retourner vers page d\'accueil',
    error500: 'Erreur 500',
    description500: 'Une erreur côté serveur s\'est produite',
    return500: 'Retourner vers page d\'accueil',
};

export default Error;
