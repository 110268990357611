const Header = {
    hello: 'Halo {{user}}',
    login: 'Aanmelden',
    cart: 'Winkelwagen',
    cartLink: 'winkelwagen',
    all: 'Alle {{segment}}',
    allCategories: 'Alle producten',
    loading: 'Even geduld...',
    Home: 'Ontvangst',
    Office: 'Kantoor',
    Gadgets: 'Relatiegeschenken',
    myAccount: {
        text: 'Profiel',
        link: 'myprintconnect/profile',
    },
    myAdresses: {
        text: 'Adresboek',
        link: 'myprintconnect/addresses_book',
    },
    myOrders: {
        text: 'Bestellingen',
        link: 'myprintconnect/orders',
    },
    myBills: {
        text: 'Facturen',
        link: 'myprintconnect/invoices',
    },
    signOut: 'Afmelden',
    unbeatablePrices: 'Wilt u profiteren van onze onverslaanbare prijzen?',
    lowPrices: 'De laagste prijzen',
    printedInBelgium: 'Gedrukt in Vlaanderen',
    quality: 'Snel en kwalitatief',
    lang: 'Taal',
};

export default Header;
