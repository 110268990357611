import Header from './layouts/main/Header';
import Footer from './layouts/main/Footer';
import Home from './containers/Partner/Home';
import Catalog from './containers/Partner/Catalog';
import Product from './containers/Partner/Product';
import Error from './containers/Error';

const benl = {
    ...Header,
    ...Footer,
    ...Home,
    ...Catalog,
    ...Product,
    ...Error,
};

export default benl;
